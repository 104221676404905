export const pricesService = {
  /**
   * Fetch all available prices
   * @returns {Promise<{ data: Array, error: Error }>}
   */
  async fetchPrices() {
    const client = useSupabaseClient();
    return await client.functions.invoke('get-prices');
  }
};
