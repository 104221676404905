<template>
  <div v-if="loading" class="flex justify-center items-center py-12">
    <div class="animate-pulse text-gray-500">Loading pricing...</div>
  </div>

  <div v-else-if="error" class="text-center py-12 text-red-600">
    {{ error }}
  </div>

  <div v-else>
    <div v-if="!props.limitSelectionTo" class="mt-12 flex justify-center">
      <fieldset aria-label="Payment frequency">
        <RadioGroup
          v-model="selectedFrequency"
          class="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs/5 font-semibold ring-1 ring-inset ring-gray-200"
        >
          <RadioGroupOption
            as="template"
            v-for="option in DEFAULT_FREQUENCIES"
            :key="option.value"
            :value="option"
            v-slot="{checked}"
          >
            <div
              :class="[
                checked ? 'bg-blue-600 text-white' : 'text-gray-500',
                'cursor-pointer rounded-full px-4 py-1'
              ]"
            >
              {{ option.label }}
            </div>
          </RadioGroupOption>
        </RadioGroup>
      </fieldset>
    </div>
    <div
      class="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 md:max-w-2xl md:grid-cols-2 lg:max-w-2xl xl:max-w-5xl xl:grid-cols-3"
    >
      <div
        v-for="tier in currentTiers"
        :key="tier.id"
        :class="[
          tier.mostPopular ? 'ring-2 ring-blue-600' : 'ring-1 ring-gray-200',
          'rounded-3xl p-8'
        ]"
      >
        <h2
          :id="tier.id"
          :class="[tier.mostPopular ? 'text-blue-600' : 'text-gray-900', 'text-lg/8 font-semibold']"
        >
          {{ tier.name }}
        </h2>
        <p class="mt-4 text-sm/6 text-gray-600">{{ tier.description }}</p>
        <p class="mt-6 flex items-baseline gap-x-1">
          <span class="text-4xl font-semibold tracking-tight text-gray-900">{{ tier.price }}</span>
          <span class="text-sm/6 font-semibold text-gray-600">{{
            selectedFrequency.priceSuffix
          }}</span>
        </p>
        <div class="mt-2">
          <p class="text-sm text-gray-500">
            {{ calculateCostPerDoc(tier) }}
          </p>
        </div>
        <a
          :href="tier.href"
          :aria-describedby="tier.id"
          :class="[
            tier.mostPopular
              ? 'bg-blue-600 text-white shadow-sm hover:bg-blue-500'
              : 'text-blue-600 ring-1 ring-inset ring-blue-200 ',
            'mt-6 block rounded-md px-3 py-2 text-center text-sm/6 font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 ',
            getButtonProps(tier).disabled
              ? 'opacity-50 cursor-not-allowed'
              : 'cursor-pointer hover:ring-blue-300 hover:bg-blue-50'
          ]"
          :disabled="getButtonProps(tier).disabled"
          @click="handleTierClick(tier)"
        >
          {{ getButtonProps(tier).label }}
        </a>
        <ul role="list" class="mt-8 space-y-3 text-sm/6 text-gray-600">
          <li v-for="feature in tier.features" :key="feature" class="flex gap-x-3">
            <CheckIcon class="h-6 w-5 flex-none text-blue-600" aria-hidden="true" />
            {{ feature }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import {RadioGroup, RadioGroupOption} from '@headlessui/vue';
import {CheckIcon} from '@heroicons/vue/20/solid';
import {computed, onMounted, ref, watch} from 'vue';
import {pricesService} from '~/lib/services/prices.service';

// Add new props
const props = defineProps({
  isLandingPage: {
    type: Boolean,
    default: false
  },
  isOnboarding: {
    type: Boolean,
    default: false
  },
  hasActiveSubscription: {
    type: Boolean,
    default: false
  },
  currentSubscription: {
    type: Object,
    default: null
  },
  limitSelectionTo: {
    type: String,
    default: null
  }
});

const prices = ref(null);
const loading = ref(true);
const error = ref(null);
const emit = defineEmits(['tier-selected']);

// Default frequency options as a constant
let DEFAULT_FREQUENCIES = [
  {value: 'monthly', label: 'Subscription', priceSuffix: '/month'},
  {value: 'one-time', label: 'One-Time', priceSuffix: '/one-time'}
];

// Initialize with the default one-time option
const selectedFrequency = ref(DEFAULT_FREQUENCIES[0]);

// Fetch prices on component mount
onMounted(async () => {
  try {
    const {data, error: fetchError} = await pricesService.fetchPrices();
    if (fetchError) {
      throw new Error(fetchError.message);
    }
    // Transform the array into an object keyed by tier_id
    prices.value = data.reduce((acc, price) => {
      acc[price.custom_data.tier_id] = {
        price_id: price.id,
        unit_price: parseInt(price.unit_price.amount),
        currency: price.unit_price.currency_code,
        paddleProductId: price.product_id,
        credits: price.custom_data.tier_credits
      };
      return acc;
    }, {});

    loading.value = false;
  } catch (err) {
    error.value = 'Failed to load pricing information';
    loading.value = false;
  }
});

const pricing = computed(() => ({
  // Use default frequencies if pricing data isn't available
  frequencies: DEFAULT_FREQUENCIES,
  oneTimeTiers: [
    {
      name: 'Starter Pack',
      id: 'tier-starter-onetime',
      paddleProductId: prices.value?.starter_onetime?.paddleProductId,
      priceId: prices.value?.starter_onetime?.price_id,
      price: formatPrice(
        prices.value?.starter_onetime?.unit_price,
        prices.value?.starter_onetime?.currency
      ),
      priceAmount: prices.value?.starter_onetime?.unit_price,
      credits: prices.value?.starter_onetime?.credits,
      description: 'The perfect entry point for individuals and small users.',
      features: [
        `${prices.value?.starter_onetime?.credits} documents`,
        'Documents never expire',
        'Smart Intelligence included',
        'Email support'
      ],
      mostPopular: false
    },
    {
      name: 'Growth Pack',
      id: 'tier-growth-onetime',
      paddleProductId: prices.value?.growth_onetime?.paddleProductId,
      priceId: prices.value?.growth_onetime?.price_id,
      price: formatPrice(
        prices.value?.growth_onetime?.unit_price,
        prices.value?.growth_onetime?.currency
      ),
      priceAmount: prices.value?.growth_onetime?.unit_price,
      credits: prices.value?.growth_onetime?.credits,
      description: 'Great value with more flexibility and a higher document volume.',
      features: [
        `${prices.value?.growth_onetime?.credits} documents`,
        'Documents never expire',
        'Smart Intelligence included',
        'Email support'
      ],
      mostPopular: true
    },
    {
      name: 'Pro Pack',
      id: 'tier-pro-onetime',
      paddleProductId: prices.value?.pro_onetime?.paddleProductId,
      priceId: prices.value?.pro_onetime?.price_id,
      price: formatPrice(
        prices.value?.pro_onetime?.unit_price,
        prices.value?.pro_onetime?.currency
      ),
      priceAmount: prices.value?.pro_onetime?.unit_price,
      credits: prices.value?.pro_onetime?.credits,
      description: 'Tailored for power users with demanding document volumes.',
      features: [
        `${prices.value?.pro_onetime?.credits} documents`,
        'Documents never expire',
        'Smart Intelligence included',
        'Email support'
      ],
      mostPopular: false
    }
  ],
  subscriptionTiers: [
    {
      name: 'Starter',
      id: 'tier-starter-subscription',
      paddleProductId: prices.value?.starter_subscription?.paddleProductId,
      priceId: prices.value?.starter_subscription?.price_id,
      price: formatPrice(
        prices.value?.starter_subscription?.unit_price,
        prices.value?.starter_subscription?.currency
      ),
      priceAmount: prices.value?.starter_subscription?.unit_price,
      credits: prices.value?.starter_subscription?.credits,
      description: 'The perfect entry point for individuals and small users.',
      features: [
        `${prices.value?.starter_subscription?.credits} documents per month`,
        'Smart Intelligence included',
        'Email support',
        'Cancel anytime'
      ],
      mostPopular: false
    },
    {
      name: 'Growth',
      id: 'tier-growth-subscription',
      paddleProductId: prices.value?.growth_subscription?.paddleProductId,
      priceId: prices.value?.growth_subscription?.price_id,
      price: formatPrice(
        prices.value?.growth_subscription?.unit_price,
        prices.value?.growth_subscription?.currency
      ),
      priceAmount: prices.value?.growth_subscription?.unit_price,
      credits: prices.value?.growth_subscription?.credits,
      description: 'Great value with more flexibility and a higher document volume.',
      features: [
        `${prices.value?.growth_subscription?.credits} documents per month`,
        'Smart Intelligence included',
        'Email support',
        'Cancel anytime'
      ],
      mostPopular: true
    },
    {
      name: 'Pro',
      id: 'tier-pro-subscription',
      paddleProductId: prices.value?.pro_subscription?.paddleProductId,
      priceId: prices.value?.pro_subscription?.price_id,
      price: formatPrice(
        prices.value?.pro_subscription?.unit_price,
        prices.value?.pro_subscription?.currency
      ),
      priceAmount: prices.value?.pro_subscription?.unit_price,
      credits: prices.value?.pro_subscription?.credits,
      description: 'Tailored for power users with demanding document volumes.',
      features: [
        `${prices.value?.pro_subscription?.credits} documents per month`,
        'Smart Intelligence included',
        'Email support',
        'Cancel anytime'
      ],
      mostPopular: false
    }
  ]
}));

// Ensure frequency has a valid value
watch(
  () => prices.value,
  newPrices => {
    if (!newPrices) {
      // Reset to default one-time option if prices fail to load
      selectedFrequency.value = DEFAULT_FREQUENCIES[0];
    }
  }
);

// Watch props.limitSelectionTo and update the frequency accordingly
watch(
  () => props.limitSelectionTo,
  newLimitSelectionTo => {
    if (newLimitSelectionTo && newLimitSelectionTo === 'one-time') {
      selectedFrequency.value = DEFAULT_FREQUENCIES[1];
    } else if (newLimitSelectionTo && newLimitSelectionTo === 'subscription') {
      selectedFrequency.value = DEFAULT_FREQUENCIES[0];
    } else {
      selectedFrequency.value = DEFAULT_FREQUENCIES[0];
    }
  }
);

const currentTiers = computed(() => {
  // Fallback to one-time tiers if frequency is somehow invalid
  const isValidFrequency = DEFAULT_FREQUENCIES.some(f => f.value === selectedFrequency.value.value);
  if (!isValidFrequency) {
    selectedFrequency.value = DEFAULT_FREQUENCIES[0];
  }

  return selectedFrequency.value.value === 'one-time'
    ? pricing.value.oneTimeTiers
    : pricing.value.subscriptionTiers;
});

// Helper function to format prices
function formatPrice(amount, currency) {
  if (!amount || !currency) return '';

  return new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 0
  }).format(amount / 100); // Divide by 100 as Paddle returns amounts in cents
}

function calculateCostPerDoc(tier) {
  if (!tier.priceAmount) return '';

  // Convert from cents to dollars/euros/etc
  const priceInMainUnit = tier.priceAmount / 100;

  // Simple division for both one-time and subscription
  const costPerDoc = (priceInMainUnit / tier.credits).toFixed(3);
  const formattedCost = formatCostPerDoc(costPerDoc);

  if (selectedFrequency.value.value === 'one-time') {
    return `${formattedCost} per document`;
  } else {
    return `${formattedCost} per document per month`;
  }
}

function formatCostPerDoc(cost) {
  // Convert to cents if less than $1
  if (parseFloat(cost) < 1) {
    const cents = (parseFloat(cost) * 100).toFixed(1);
    return `${cents}¢`;
  }

  // Format as currency if $1 or more
  return new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: prices.value?.starter_onetime?.currency || 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(cost);
}

// Add new computed property for button labels
const getButtonProps = computed(() => tier => {
  const isSubscription = selectedFrequency.value.value === 'monthly';
  let isCurrentPlan = false;

  // Check if the current plan is the same as the selected tier
  if (isSubscription && props.currentSubscription) {
    isCurrentPlan =
      isSubscription && Number(tier.credits) === props.currentSubscription.total_credits;
  }

  if (props.isLandingPage) {
    return {
      label: 'Get started',
      disabled: false
    };
  } else if (props.isOnboarding) {
    return {
      label: 'Continue to Checkout',
      disabled: false
    };
  } else if (isSubscription) {
    if (isCurrentPlan) {
      return {
        label: 'Current plan',
        disabled: true
      };
    } else if (!props.hasActiveSubscription) {
      return {
        label: 'Upgrade to subscription',
        disabled: false
      };
    }
    return {
      label: 'Switch plan',
      disabled: false
    };
  }

  return {
    label: 'Buy pack',
    disabled: false
  };
});

function handleTierClick(tier) {
  // If the tier is disabled, do nothing
  if (getButtonProps.value(tier).disabled) return;

  // If we are on the landing page, redirect to the register page
  if (props.isLandingPage) {
    navigateTo('/app/register');
  }

  // If we are on the onboarding page, emit an event to the parent
  emit('tier-selected', tier);
}
</script>
